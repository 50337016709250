export const RoleCode = Object.freeze({
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  ADMIN: 'ADMIN',
})

export const PermissionCode = Object.freeze({
  MANAGE: 'manage',
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  PRINT: 'PRINT',
  FETCH: 'FETCH',
})

export const ResourceCode = Object.freeze({
  ALL: 'all',
  ACCOUNT: 'ACCOUNT',
  ACCOUNT_ROLE: 'ACCOUNT_ROLE',
  PERMISSION: 'PERMISSION',
  RESOURCE: 'RESOURCE',
  ROLE: 'ROLE',
  ROLE_PERMISSION_RESOURCE: 'ROLE_PERMISSION_RESOURCE',
  MENU: 'MENU',
  MIDDLE_SCORE: 'MIDDLE_SCORE',
  SCORE_LOCK_CREDIT_CLASS: 'SCORE_LOCK_CREDIT_CLASS',
  SCORE_LOCK_EXAMINATION_ROOM: 'SCORE_LOCK_EXAMINATION_ROOM',
  STUDENT_CONDUCT: 'STUDENT_CONDUCT',
  CONDUCT: 'CONDUCT',
  CONDUCT_CRITERIA: 'CONDUCT_CRITERIA',
  COEFFICIENT_SCORE_TWO: 'COEFFICIENT_SCORE_TWO',
  CHECK_EXAMINATION_PAPER: 'CHECK_EXAMINATION_PAPER',
  CREDIT_CLASS_DOCUMENT: 'CREDIT_CLASS_DOCUMENT',
  PROGRAMME_SUBJECTS: 'PROGRAMME_SUBJECTS',
  PORTAL_PROGRAMME: 'PORTAL_PROGRAMME',
  PORTAL_UNIVERSITY_ANNOUNCEMENT: 'PORTAL_UNIVERSITY_ANNOUNCEMENT',
  PORTAL_GRADUATE_ANNOUNCEMENT: 'PORTAL_GRADUATE_ANNOUNCEMENT',
  PORTAL_DASHBOARD: 'PORTAL_DASHBOARD',
  PORTAL_DOCUMENTS: 'PORTAL_DOCUMENTS',
  PAYMENT_RETURN_URL: 'PAYMENT_RETURN_URL',
  PORTAL_REGISTER_SUBJECT: 'PORTAL_REGISTER_SUBJECT',
  PORTAL_STUDY_PROCESS: 'PORTAL_STUDY_PROCESS',
  PORTAL_LEARNING_OUTCOME: 'PORTAL_LEARNING_OUTCOME',
  PORTAL_PUBLICATION: 'PORTAL_PUBLICATION',
  PORTAL_BORROWING: 'PORTAL_BORROWING',
  STUDENT_EVALUATE: 'STUDENT_EVALUATE',
  PORTAL_SEARCH_CERTIFICATE: 'PORTAL_SEARCH_CERTIFICATE',
  PORTAL_CREDENTIAL_PROCESS_HISTORY: 'PORTAL_CREDENTIAL_PROCESS_HISTORY',
  LIST_CREDENTIALS: 'LIST_CREDENTIALS',
  PORTAL_REGISTER_GRADUATION_PROJECT: 'PORTAL_REGISTER_GRADUATION_PROJECT',
  PORTAL_STUDENT_RECEIPT: 'PORTAL_STUDENT_RECEIPT'
})

export default { RoleCode, PermissionCode, ResourceCode }
