export default class CustomError {
  code;

  message;

  constructor(code, message) {
    this.code = code
    this.message = message
  }
}
