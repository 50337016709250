import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export const baseAttr = Object.freeze({
    basePath: ApiExtRoutes.studentEvaluate,
})

export default {
    namespaced: true,
    state: {
        studentEvaluate: {},
    },
    getters: {
        studentEvaluate: state => state.studentEvaluate,
    },
    mutations: {
        SET_STUDENT_EVALUATE: (state, studentEvaluate) => {
            state.studentEvaluate = studentEvaluate
        },
    },
    actions: {
        async getStudentEvaluate({ commit }, params) {
            try {
                const response = await axios.get(`surveys/getDataEvaluate`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_STUDENT_EVALUATE', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createStudentEvaluate({ commit }, params) {
            try {
                const response = await axios.post(`surveys/createEvaluate`, params)
                const { data } = response;
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateStudentEvaluate({ commit }, params) {
            try {
                const response = await axios.put(`surveys/updateEvaluate/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
