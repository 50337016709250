import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    schedules: [],
    schedulers: [],
    total: 0,
  },
  getters: {
    schedules: state => state.schedules,
    schedulers: state => state.schedulers,
    total: state => state.total,
  },
  mutations: {
    SET_SCHEDULES: (state, schedules) => { state.schedules = schedules },
    SET_SCHEDULERS: (state, schedulers) => { state.schedulers = schedulers },
    SET_TOTAL: (state, total) => { state.total = total },
  },

  actions: {

    async getSchedules({ commit }, params) {
      try {
        const response = await axios.post('uni/schedules', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_SCHEDULES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getSchedulersV3({ commit }, params) {
      try {
        const response = await axios.get('uni/schedules/getSchedulesV3', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_SCHEDULERS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getSchedulers({ commit }, params) {
      try {
        const response = await axios.get('uni/schedules', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_SCHEDULERS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
