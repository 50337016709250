import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/finance/receipt',
    name: 'studentReceipt',
    component: () => import('@/views/finance/receipt/Index.vue'),
    meta: {
      resource: ResourceCode.PORTAL_STUDENT_RECEIPT,
    },
  },
]
