import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    documents: [],
    url: '',
    students: [],
    details: {},
  },
  getters: {
    documents: state => state.documents,
    url: state => state.url,
    students: state => state.students,
    details: state => state.details,
  },
  mutations: {
    SET_DOCUMENTS: (state, data) => { state.documents = data },
    SET_URL: (state, data) => { state.url = data },
    SET_STUDENT: (state, data) => { state.students = data },
    SET_DETAIL: (state, data) => { state.details = data },
  },
  actions: {
    async getCreditClassDocuments({ commit }, params) {
      try {
        const response = await axios.get('uni/creditClassMedias', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DOCUMENTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentByCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getAllStudentCreditClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCreditClassDetail({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getCreditClassDetail', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DETAIL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadCreditClassDocument({ commit }, params) {
      try {
        const response = await axios.get('uni/creditClassMedias/download', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_URL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
