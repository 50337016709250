import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { PROGRAMME_STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    programmes: [],
    totalRows: 0,
    statuses: PROGRAMME_STATUSES,
    resourceName: { fullName: 'Chương trình đào tạo', shortName: 'CTĐT' },
  },
  getters: {
    programmes: state => state.programmes,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_PROGRAMMES: (state, { total, records }) => {
      state.totalRows = total
      state.programmes = records
    },
  },
  actions: {
    async getProgrammes({ commit }, params) {
      try {
        const response = await axios.get('uni/programmes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PROGRAMMES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
