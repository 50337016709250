import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
export const getUserString = () => localStorage.getItem(jwtDefaultConfig.storageUserName)

export const getUser = () => JSON.parse(getUserString())

export const setUser = value => localStorage.setItem(jwtDefaultConfig.storageUserName, value)

export const getToken = () => localStorage.getItem(jwtDefaultConfig.storageAccessTokenKeyName)

export const setToken = value => localStorage.setItem(jwtDefaultConfig.storageAccessTokenKeyName, value)

export const getIdToken = () => localStorage.getItem(jwtDefaultConfig.storageIdTokenKeyName)

export const setIdToken = value => localStorage.setItem(jwtDefaultConfig.storageIdTokenKeyName, value)

export const getLoginType = () => localStorage.getItem(jwtDefaultConfig.storageLoginTypeKeyName)

export const setLoginType = value => localStorage.setItem(jwtDefaultConfig.storageLoginTypeKeyName, value)

export const getRefreshToken = () => localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName)

export const setRefreshToken = value => localStorage.setItem(jwtDefaultConfig.storageRefreshTokenKeyName, value)

export const isUserLoggedIn = () => !!(getUserString() && getToken())

export const removeUser = () => localStorage.removeItem(jwtDefaultConfig.storageUserName)

export const removeToken = () => localStorage.removeItem(jwtDefaultConfig.storageAccessTokenKeyName)

export const removeRefreshToken = () => localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)

export const removeLoginType = () => localStorage.removeItem(jwtDefaultConfig.storageLoginTypeKeyName)

export const removeIdToken = () => localStorage.removeItem(jwtDefaultConfig.storageIdTokenKeyName)

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
  const isLoggedIn = isUserLoggedIn()
  if (isLoggedIn) return '/'
  return { name: 'login' }
}
