import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://api-qldt.ninhbinh.vnpt.vn/api/v1/',
  // baseURL: 'https://api-demo.daihocso.com.vn/api/v1/',
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 60000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
