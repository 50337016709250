import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    // Notification bell
    totalUnread: 0,
    notificationsByAccount: [],

    // Credit class notifications
    creditClassNotifications: [],

    // Class notifications
    classNotifications: [],
  },
  getters: {
    totalUnread: state => state.totalUnread,
    notificationsByAccount: state => state.notificationsByAccount,
    creditClassNotifications: state => state.creditClassNotifications,
    classNotifications: state => state.classNotifications,
  },
  mutations: {
    SET_TOTAL_UNREAD: (state, data) => { state.totalUnread = data },
    SET_NOTIFICATIONS_BY_ACCOUNT: (state, data) => { state.notificationsByAccount = data },
    SET_CREDIT_CLASS_NOTIFICATIONS: (state, data) => { state.creditClassNotifications = data },
    SET_CLASS_NOTIFICATIONS: (state, data) => { state.classNotifications = data },
  },
  actions: {
    async countNotificationsUnreadByAccount({ commit }) {
      try {
        const response = await axios.get('uni/notifications/countUnreadByAccount')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TOTAL_UNREAD', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getNotificationsByAccount({ commit }) {
      try {
        const response = await axios.get('uni/notifications/getByAccount')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_NOTIFICATIONS_BY_ACCOUNT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getNotificationsByCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getNotificationsByCreditClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CREDIT_CLASS_NOTIFICATIONS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getNotificationsByClass({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getNotificationsByClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CLASS_NOTIFICATIONS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateNotificationReceiverRead({ commit }, id) {
      try {
        const response = await axios.post(`uni/notificationReceivers/${id}/read`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
