import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import {
  IS_OPTIONAL_SYMBOLS,
  STATUS_SYMBOLS,
  REPLACE_THESIS_SUBJECT_TYPES,
} from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statusSymbols: STATUS_SYMBOLS,
    countedForGpaTypes: STATUS_SYMBOLS,
    isOptionalTypes: IS_OPTIONAL_SYMBOLS,
    replaceThesisSubjectTypes: REPLACE_THESIS_SUBJECT_TYPES,
    resourceName: { fullName: 'Học phần CTĐT', shortName: 'HP' },
    maxOrderNo: 0,
    subjects: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    statusSymbols: state => state.statusSymbols,
    resourceName: state => state.resourceName,
    subjects: state => state.subjects,
    countedForGpaTypes: state => state.countedForGpaTypes,
    isOptionalTypes: state => state.isOptionalTypes,
    replaceThesisSubjectTypes: state => state.replaceThesisSubjectTypes,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/programme/subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
