import Vue from 'vue'
import VueRouter from 'vue-router'
import { getLoginType, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import store from '@/store'
import ability from '@/libs/acl/ability'
import payment from '@/router/routes/payment'
import forgotPassword from '@/router/routes/forgot-password'
import { LOGIN_TYPE } from '@/const/type'
import certificate from '@/router/routes/certificate'
import auth from './routes/auth'
import announcement from './routes/announcement'
import dashboard from './routes/dashboard'
import study from './routes/study'
import registerSubject from './routes/register-subject'
import news from './routes/news'
import profile from './routes/profile'
import examination from './routes/examination'
// import document from './routes/document'
import digitalLibrary from '@/router/routes/digital-library'
import evaluate from './routes/evaluate'
import finance from './routes/finance'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...announcement,
    ...dashboard,
    ...study,
    ...registerSubject,
    ...news,
    ...profile,
    ...examination,
    // ...document,
    ...forgotPassword,
    ...payment,
    ...digitalLibrary,
    ...evaluate,
    ...certificate,
    ...finance,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'login' })
    return next({ name: 'notAuthorized' })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'portalDashboard' })
  }
  if (isLoggedIn) {
    let user = null
    const loginType = getLoginType()
    if (loginType === LOGIN_TYPE.NORMAL) {
      user = await store.dispatch('auth/verify')
    } else if (loginType === LOGIN_TYPE.SSO) {
      user = await store.dispatch('auth/verifySso')
    }
    if (user) {
      ability.update(user.abilities)
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
