import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export const defaultParams = Object.freeze({
  currentPage: 1,
  itemsPerPage: 100,
  status: 1,
  sort: '1_asc',
})
export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    courseSemesters: [],
    departments: [],
    majors: [],
    subjects: [],
    creditClasses: [],
    days: [],
    turns: [],
    rooms: [],
    creditClassRooms: [],
    teachers: [],
    classes: [],
    studentClasses: [],
    students: [],
    creditClassRoomByCreditClass: [],
    creditClassTeachers: [],
    buildings: [],
    programmes: [],
    programmeSubjects: [],
    titles: [],
    programmeSubjectGroups: [],
    articleCategories: [],
    teachersByCreditClass: [],
    genders: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    courseSemesters: state => state.courseSemesters,
    departments: state => state.departments,
    majors: state => state.majors,
    subjects: state => state.subjects,
    creditClasses: state => state.creditClasses,
    days: state => state.days,
    turns: state => state.turns,
    rooms: state => state.rooms,
    creditClassRooms: state => state.creditClassRooms,
    classes: state => state.classes,
    studentClasses: state => state.studentClasses,
    teachers: state => state.teachers,
    students: state => state.students,
    creditClassRoomByCreditClass: state => state.creditClassRoomByCreditClass,
    creditClassTeachers: state => state.creditClassTeachers,
    buildings: state => state.buildings,
    programmes: state => state.programmes,
    programmeSubjects: state => state.programmeSubjects,
    titles: state => state.titles,
    programmeSubjectGroups: state => state.programmeSubjectGroups,
    articleCategories: state => state.articleCategories,
    teachersByCreditClass: state => state.teachersByCreditClass,
    genders: state => state.genders,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
    SET_COURSES: (state, records) => {
      state.courses = records
    },
    SET_COURSE_SEMESTERS: (state, records) => {
      state.courseSemesters = records
    },
    SET_DEPARTMENTS: (state, records) => {
      state.departments = records
    },
    SET_MAJORS: (state, records) => {
      state.majors = records
    },
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
    SET_DAYS: (state, records) => {
      state.days = records
    },
    SET_TURNS: (state, records) => {
      state.turns = records
    },
    SET_ROOMS: (state, records) => {
      state.rooms = records
    },
    SET_CREDIT_CLASS_ROOMS: (state, records) => {
      state.creditClassRooms = records
    },
    SET_CLASSES: (state, records) => {
      state.classes = records
    },
    SET_STUDENT_CLASSES: (state, records) => {
      state.studentClasses = records
    },
    SET_TEACHERS: (state, records) => {
      state.teachers = records
    },
    SET_STUDENTS: (state, records) => {
      state.students = records
    },
    SET_CREDIT_CLASSES: (state, records) => {
      state.creditClasses = records
    },
    SET_CREDIT_CLASSES_ROOMS: (state, records) => {
      state.creditClassRoomByCreditClass = records
    },
    SET_BUILDINGS: (state, records) => {
      state.buildings = records
    },
    SET_PROGRAMMES: (state, records) => {
      state.programmes = records
    },
    SET_PROGRAMME_SUBJECTS: (state, records) => {
      state.programmeSubjects = records
    },
    SET_TITLES: (state, records) => {
      state.titles = records
    },
    SET_CREDIT_CLASS_TEACHERS: (state, records) => {
      state.creditClassTeachers = records
    },
    SET_PROGRAMME_SUBJECT_GROUPS: (state, records) => {
      state.programmeSubjectGroups = records
    },
    SET_PORTAL_CATEGORIES: (state, records) => {
      state.articleCategories = records
    },
    SET_TEACHERS_BY_CREDIT_CLASS: (state, records) => {
      state.teachersByCreditClass = records
    },
    SET_GENDERS: (state, records) => {
      state.genders = records
    },
  },
  actions: {

    async getCreditClassRoomsByCreditClass({ commit }, parameters) {
      try {
        const params = {
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassRoomByCreditClass, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDIT_CLASSES_ROOMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystems({ commit }, parameters) {
      try {
        const ownParams = { organizationId: null, trainingFormId: '', sort: '2_asc' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.trainingSystem, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TRAINING_SYSTEMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, parameters) {
      try {
        const ownParams = { trainingSystemId: '', coeffectionId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.course}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_COURSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getCoursesByStudentId({ commit }, parameters) {
      try {
        const params = {
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.course}/get_course_by_student`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_COURSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getCourseSemesters({ commit }, parameters) {
      try {
        const ownParams = { courseId: '', status: 1 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.courseSemester}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_COURSE_SEMESTERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourseSemestersNew({ commit }, parameters) {
      try {
        const ownParams = { courseId: '', status: 1 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.courseSemester}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.name,
            status: item.status,
          }))
          commit('SET_COURSE_SEMESTERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getDepartments({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '', leaderId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.department, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_DEPARTMENTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMajors({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.major}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_MAJORS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getSubjects({ commit }, parameters) {
      try {
        const ownParams = {
          subjectTypeId: null,
          departmentId: '',
          programmeId: '',
          itemsPerPage: 1000,
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.subject, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getClasses({ commit }, parameters) {
      try {
        const ownParams = { majorId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.class, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CLASSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getRooms({ commit }, parameters) {
      try {
        const ownParams = { buildingId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.rooms, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_ROOMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCreditClassRooms({ commit }, parameters) {
      try {
        const params = {
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassRoom, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDIT_CLASS_ROOMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTeachers({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.teacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDays({ commit }) {
      try {
        const response = await axios.get(ApiExtRoutes.days)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          const mapData = mapDropdown(records)
          commit('SET_DAYS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTurns({ commit }) {
      try {
        const response = await axios.get(ApiExtRoutes.turns)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          const mapData = mapDropdown(records)
          commit('SET_TURNS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getStudentClasses({ commit }, parameters) {
      try {
        const ownParams = { classId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.student, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.studentClassId,
            label: item.name,
          }))
          commit('SET_STUDENT_CLASSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCreditClasses({ commit }, parameters) {
      try {
        const ownParams = { courseSemesterId: '', subjectId: '', status: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClass, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.code,
          }))
          commit('SET_CREDIT_CLASSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getBuildings({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.building, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_BUILDINGS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgrammes({ commit }, parameters) {
      try {
        const ownParams = {
          organizationId: '',
          courseId: '',
          majorId: '',
          subjectId: '',
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.programme, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_PROGRAMMES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgrammeSubjects({ commit }, parameters) {
      try {
        const ownParams = {
          organizationId: '',
          courseId: '',
          subjectId: '',
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.programmeSubject, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.subjectId,
            label: item.name,
          }))
          commit('SET_PROGRAMME_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTitles({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.title, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TITLES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCreditClassTeachers({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassTeacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDIT_CLASS_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeacherByCreditClass({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassTeacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.teacherId,
            label: item.name,
          }))
          commit('SET_CREDIT_CLASS_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgrammeSubjectGroups({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.programmeSubjectGroup, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_PROGRAMME_SUBJECT_GROUPS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getArticleCategories({ commit }) {
      try {
        const response = await axios.get(ApiExtRoutes.articleCategories)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = mapDropdown(data.data)
          commit('SET_PORTAL_CATEGORIES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachersByCreditClass({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.teachersByCreditClass, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TEACHERS_BY_CREDIT_CLASS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getGenders({ commit }) {
      try {
        const response = await axios.get('categories/factGenders/allActive')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_GENDERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
