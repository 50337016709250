// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import Vue from 'vue'
import { getMessaging, onMessage } from 'firebase/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC7vn-1FdroQwc16Woq0mhKrhdKfUrXm3Q',
  authDomain: 'qldt-dh-hoa-lu.firebaseapp.com',
  projectId: 'qldt-dh-hoa-lu',
  storageBucket: 'qldt-dh-hoa-lu.appspot.com',
  messagingSenderId: '562924202846',
  appId: '1:562924202846:web:cb2921a5001b0d9feebdcf',
  measurementId: 'G-R3K2VEX53N',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging()
onMessage(messaging, payload => {
  console.log('Message received. ', payload)
  // ...
})
Vue.prototype.$messaging = app

export default app
