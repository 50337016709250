import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    // Process
    accumulatedCredits: 0,
    totalCredits: 0,
    processes: [],

    // LearningOutcome
    learningOutcomes: [],
    scores: [],

    // CreditClass
    creditClasses: [],
    courseSemesters: [],

    // Classes
    classes: [],
    studentsClass: [],
  },
  getters: {
    accumulatedCredits: state => state.accumulatedCredits,
    totalCredits: state => state.totalCredits,
    processes: state => state.processes,
    courseSemesters: state => state.courseSemesters,
    learningOutcomes: state => state.learningOutcomes,
    creditClasses: state => state.creditClasses,
    classes: state => state.classes,
    studentsClass: state => state.studentsClass,
    scores: state => state.scores,
  },
  mutations: {
    SET_ACCUMULATED_CREDITS: (state, data) => { state.accumulatedCredits = data },
    SET_TOTAL_CREDITS: (state, data) => { state.totalCredits = data },
    SET_COURSE_SEMESTERS: (state, data) => { state.courseSemesters = data },
    SET_LEARNING_OUTCOMES: (state, data) => { state.learningOutcomes = data },
    SET_CREDIT_CLASSES: (state, data) => { state.creditClasses = data },
    SET_PROCESSES: (state, data) => { state.processes = data },
    SET_CLASSES: (state, data) => { state.classes = data },
    SET_STUDENTS_CLASS: (state, data) => { state.studentsClass = data },
    SET_SCORES: (state, data) => { state.scores = data },
  },
  actions: {
    async getCourseSemestersByProgramme({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getCourseSemestersByProgramme', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSE_SEMESTERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudyProcess({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getStudyProcess', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { accumulativeCredits, totalCredits, processes } = data.data
          commit('SET_ACCUMULATED_CREDITS', accumulativeCredits)
          commit('SET_TOTAL_CREDITS', totalCredits)
          commit('SET_PROCESSES', processes)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getLearningOutcome({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getLearningOutcome', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_LEARNING_OUTCOMES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCreditClassesByCourseSemester({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getCreditClassesByCourseSemester', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CREDIT_CLASSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getClassesByStudentAndProgramme({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getClassesByStudentAndProgramme', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CLASSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentsByClass({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getStudentsByClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENTS_CLASS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAllScoresByStudentCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getAllScoreByCreditClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_SCORES', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
