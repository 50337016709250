import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { CREDIT_CLASS_STATUSES } from '@/const/selection'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.creditClass,
  maxOrderNoPath: ApiExtRoutes.creditClassMaxOrderNo,
  copyPath: ApiExtRoutes.creditClassCopy,
  planPath: ApiExtRoutes.planCreditClass,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: CREDIT_CLASS_STATUSES,
    resourceName: {
      fullName: 'Lớp độc lập',
      shortName: 'Lớp ĐL',
    },
    maxOrderNo: 0,
    practiceScoreStudentOptions: [],
    totalTheoreticalScores: 0,
    theoreticalScores: [],
    planDataLists: [],
    planTotalRows: 0,
    componentCoefficientScoreTwoDataSources: [],
    practiceScores: [],
    creditClassesBySetOfCriteriaObjectDataLists: [],
    creditClassRoomsBySetOfCriteriaObjectDataLists: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    practiceScoreStudentOptions: state => state.practiceScoreStudentOptions,
    totalTheoreticalScores: state => state.totalTheoreticalScores,
    theoreticalScores: state => state.theoreticalScores,
    planDataLists: state => state.planDataLists,
    planTotalRows: state => state.planTotalRows,
    componentCoefficientScoreTwoDataSources: state => state.componentCoefficientScoreTwoDataSources,
    practiceScores: state => state.practiceScores,
    creditClassesBySetOfCriteriaObjectDataLists: state => state.creditClassesBySetOfCriteriaObjectDataLists,
    creditClassRoomsBySetOfCriteriaObjectDataLists: state => state.creditClassRoomsBySetOfCriteriaObjectDataLists,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_STUDENT_OPTIONS(state, students) {
      state.practiceScoreStudentOptions = students
    },
    SET_THEORETICAL_SCORES(state, records) {
      state.theoreticalScores = records
    },
    SET_TOTAL_ROWS_THEORETICAL_SCORES(state, total) {
      state.totalTheoreticalScores = total
    },
    ADD_PRACTICE_SCORE(state, data) {
      state.practiceScores.push(data)
    },
    DELETE_PRACTICE_SCORE(state, id) {
      state.practiceScores = state.practiceScores.filter(element => element.practiceScoreId !== id)
    },
    SET_CREDIT_CLASSES: (state, data) => {
      state.dataLists = data
    },
    SET_PLAN_LISTS: (state, { total, records }) => {
      state.planTotalRows = total
      state.planDataLists = records
    },
    SET_COMPONENT_COEFFICIENT_SCORE_TWO_DATA_SOURCES: (state, data) => {
      state.componentCoefficientScoreTwoDataSources = data
    },
    SET_PRACTICE_SCORES: (state, data) => {
      state.practiceScores = data
    },
    SET_CREDIT_CLASSES_BY_SET_OF_CRITERIA_OBJECT_LISTS: (state, data) => {
      state.creditClassesBySetOfCriteriaObjectDataLists = data
    },
    SET_CREDIT_CLASS_ROOMS_BY_SET_OF_CRITERIA_OBJECT_LISTS: (state, data) => {
      state.creditClassRoomsBySetOfCriteriaObjectDataLists = data
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async copyData(commit, params) {
      try {
        const response = await axios.post(`${baseAttr.copyPath}${params.classId}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getStudentTheoreticalScores({ commit }, params) {
      try {
        const response = await axios.get('uni/studentsCreditClasses/theoreticalScores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_THEORETICAL_SCORES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentPracticeScores({ commit }, params) {
      try {
        const response = await axios.get('uni/studentsCreditClasses/practiceScores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PRACTICE_SCORES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentCreditClasses({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/list', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENT_CREDIT_CLASS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createTheoreticalScore({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/theoreticalScore', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async createPracticeScore({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/practiceScore', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getStudentsInCreditClass({ commit }, creditClassId) {
      try {
        const response = await axios.get(`uni/studentsCreditClasses/${creditClassId}/students`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { students } = data.data
          commit('SET_STUDENT_OPTIONS', students)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deletePracticeScore({ commit }, { studentCreditClassScoreId, studentCreditClassId }) {
      try {
        const response = await axios.delete('uni/studentsCreditClasses/practiceScore', { data: { studentCreditClassScoreId, studentCreditClassId } })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getScoresInTheoreticalCreditClass({ commit }, { id, params }) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/${id}/scoresInTheoreticalCreditClass`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_THEORETICAL_SCORES', records)
          commit('SET_TOTAL_ROWS_THEORETICAL_SCORES', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars,consistent-return
    async importStudentCreditClass({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/importStudent', { params })
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },
    async getScoreLockCreditClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_classes/scoreLock', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateScoreLockCreditClasses({ commit }, params) {
      try {
        const response = await axios.put('uni/credit_classes/scoreLock', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getPlanData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.planPath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_PLAN_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createPlanData(commit, params) {
      try {
        const response = await axios.post(baseAttr.planPath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getComponentCoefficientScoreTwo({ commit }, params) {
      try {
        const response = await axios.get('uni/studentsCreditClasses/componentCoefficientScoreTwo', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COMPONENT_COEFFICIENT_SCORE_TWO_DATA_SOURCES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async saveComponentCoefficientScoreTwo({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/componentCoefficientScoreTwo', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCreditClassesBySetOfCriteriaObject({ commit }, params) {
      try {
        const response = await axios.get('surveys/getCreditClassesBySetOfCriteriaObject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CREDIT_CLASSES_BY_SET_OF_CRITERIA_OBJECT_LISTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
