import { Ability } from '@casl/ability'
import { getUser } from '@/auth/utils'
import { initialAbilities } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const user = getUser()
const existingAbilities = user ? user.abilities : null

export default new Ability(existingAbilities || initialAbilities)
