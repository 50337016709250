import { CREDENTIAL_TYPE, STATUSES, STATUSES_CREDENTIAL } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statusesCredential: STATUSES_CREDENTIAL,
    statuses: STATUSES,
    credentialsOfStudent: [],
    totalRows: 0,
    types: CREDENTIAL_TYPE,
  },
  getters: {
    statusesCredential: state => state.statusesCredential,
    statuses: state => state.statuses,
    credentialsOfStudent: state => state.credentialsOfStudent,
    totalRows: state => state.totalRows,
    types: state => state.types,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_CREDENTIALS_OF_STUDENT: (state, credentialsOfStudent) => {
      state.credentialsOfStudent = credentialsOfStudent
    },
  },
  actions: {
    async readStudentsCredentialStorage({ commit }, params) {
      try {
        const response = await axios.get('certificates/credentials-storage', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_CREDENTIALS_OF_STUDENT', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
