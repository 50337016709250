import { PermissionCode, ResourceCode } from '@/const/code';

export default [
  {
    path: '/payments/init',
    name: 'payment',
    component: () => import('@/views/payment/Payment.vue'),
    meta: {
      resource: ResourceCode.PAYMENT_RETURN_URL,
    },
  },
  {
    path: '/payments/returnUrl',
    name: 'paymentReturnUrl',
    component: () => import('@/views/payment/PaymentReturn.vue'),
    meta: {
      resource: ResourceCode.PAYMENT_RETURN_URL,
      layout: 'full',
    },
  },
]
