import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'
import { FILTER_REGISTER_SUBJECT } from '@/const/selection'

export default {
  namespaced: true,
  state: {
    registerDataSources: [],
    registeredDataSources: [],
    options: FILTER_REGISTER_SUBJECT,
  },
  getters: {
    registerDataSources: state => state.registerDataSources,
    registeredDataSources: state => state.registeredDataSources,
    options: state => state.options,
  },
  mutations: {
    SET_REGISTER_DATA_SOURCES: (state, data) => { state.registerDataSources = data },
    SET_REGISTERED_DATA_SOURCES: (state, data) => { state.registeredDataSources = data },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getRegisterSubjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { registerSubjects, registeredSubjects } = data.data
          commit('SET_REGISTER_DATA_SOURCES', registerSubjects)
          commit('SET_REGISTERED_DATA_SOURCES', registeredSubjects)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line consistent-return
    async checkOverMinCredit({ commit }, params) {
      try {
        const response = await axios.post('uni/portals/checkOverMinCredit', params)
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentRegisterSubject({ commit }, params) {
      try {
        const response = await axios.post('mq/studentRegisterSubjects', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentRegisteredSubject({ commit }, params) {
      try {
        const response = await axios.delete('uni/portals/deleteStudentRegisteredSubject', { data: params })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
